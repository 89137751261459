import { Image, styled } from '@obvio/app'
import { RichText } from '@obvio/template'
import { Stack } from '@obvio/ui'

import { CtaLink } from '@/components/CtaLink'

import type { ImageAsset } from '@obvio/app'
import type { ReactElement } from 'react'

const Img = styled(Image)`
  max-width: 22rem;
  margin-left: auto !important;
  margin-right: auto !important;
`

const WrapDescription = styled.div`
  max-width: 50rem;
  margin-left: auto !important;
  margin-right: auto !important;
`

const Wrap = styled(Stack)`
  text-align: center;
`

type HeadingRichTextCtaImageProps = {
  description: string
  description2: string
  image?: ImageAsset
  ctaText?: string
  url?: string
}

export function HeadingRichTextCtaImage2({
  description,
  description2,
  image,
  ctaText,
  url,
}: HeadingRichTextCtaImageProps): ReactElement {
  return (
    <Wrap kind="vertical" spacing="big">
      <div>
        <RichText value={description} />
      </div>
      <Img img={image} aspectRatio sizes="400px" />
      <WrapDescription>
        <RichText value={description2} />
      </WrapDescription>
      {ctaText && url ? <CtaLink as="h3" href={url} text={ctaText} /> : null}
    </Wrap>
  )
}
